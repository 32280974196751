<template>
  <v-row class="ma-3 justify-center">
    <v-card
      v-if="!organization"
      class="px-3 col-12 elevation-0"
    >
      <v-card-text class="error-message pa-3">
        <v-row>
          <v-col>
            <v-icon>error_outline</v-icon>

            <span class="header-2 pl-2">
              Not Found
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      v-else-if="isOurCompany"
      class="px-3 col-12 elevation-0"
    >
      <v-card-title>
        <v-col class="col-2">
          <v-avatar
            class="elevation-1"
          >
            <img
              :src="icons.organization"
              alt="Avatar"
              color="offwhite"
              size="48"
            >
          </v-avatar>
        </v-col>

        <v-col>
          <v-row class="col-12 header-2">
            OAO
          </v-row>
        </v-col>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col class="title col-2">
            Profile
          </v-col>

          <v-col class="pl-2">
            <v-col class="py-2">
              <div class="font-weight-bold">
                Headquarters
              </div>

              <div>
                451 Broadway, 3rd Floor<br>
                New York, NY 10013
              </div>
            </v-col>

            <v-col class="py-2">
              <div class="font-weight-bold">
                California
              </div>

              <div>
                22 Battery Street, #700<br>
                San Francisco, CA 94111
              </div>
            </v-col>

            <v-col class="py-2">
              <div class="font-weight-bold">
                Colorado
              </div>

              <div>
                8700 Turnpike Drive, Suite 495<br>
                Westminster, CO 80031
              </div>
            </v-col>

            <v-col class="py-2">
              <div class="font-weight-bold">
                Illinois
              </div>

              <div>
                350 North LaSalle Blvd, Suite 420<br>
                Chicago, IL 60654
              </div>
            </v-col>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="title col-2">
            Metadata
          </v-col>

          <v-col class="pl-2">
            <v-col>
              Formed in

              <span class="font-weight-bold">
                2001
              </span>

              by

              <span class="font-weight-bold">
                Craig Leshen
              </span>
            </v-col>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <template v-else>
      <v-col class="col-9">
        <v-card
          class="px-3 pt-3 pb-6 elevation-0"
        >
          <v-card-title class="darkblue--text">
            {{ organization.name || 'Organization Name Unknown' }}
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col
                v-if="isInternalAdminUser"
              >
                <v-row class="subtitle-2">
                  <v-col>
                    <v-tooltip left>
                      <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                        <div class="cursor-pointer midgray--text">
                          <span
                            v-bind="tooltipAttrs"
                            v-on="tooltipOn"
                          >
                            Client ID
                          </span>
                          <span
                            class="toasted--text"
                          >
                            *
                          </span>
                        </div>
                      </template>

                      <span>Admin Only</span>
                    </v-tooltip>
                  </v-col>
                </v-row>

                <v-row class="body-2 mt-0">
                  <v-col class="py-0 font-weight-medium nearblack--text">
                    {{ organization.clientId || 'None' }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-row class="subtitle-2">
                  <v-col class="midgray--text">
                    Publisher ID
                  </v-col>
                </v-row>
                <v-row class="body-2 mt-0">
                  <v-col class="py-0 font-weight-medium nearblack--text">
                    {{ organization.publisherId || 'None' }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-row class="subtitle-2">
                  <v-col class="midgray--text">
                    Billing Code
                  </v-col>
                </v-row>
                <v-row class="body-2 mt-0">
                  <v-col class="py-0 font-weight-medium nearblack--text">
                    {{ organization.billingCode || 'None' }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-row class="subtitle-2">
                  <v-col class="midgray--text">
                    Network Code
                  </v-col>
                </v-row>
                <v-row class="body-2 mt-0">
                  <v-col
                    v-if="adServerUrl"
                    class="py-0 font-weight-medium nearblack--text"
                  >
                    <a
                      :href="adServerUrl"
                      target="_blank"
                    >
                      {{ organization.networkCode }}
                    </a>
                  </v-col>

                  <v-col
                    v-else
                    class="py-0 font-weight-medium nearblack--text"
                  >
                    None
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card
          class="px-3 pt-3 pb-6 elevation-0"
        >
          <v-card-subtitle class="font-weight-bold midgray--text text-uppercase">
            Service Data
          </v-card-subtitle>

          <v-card-text>
            <v-row>
              <v-col>
                <v-row class="subtitle-2">
                  <v-col class="midgray--text">
                    Status
                  </v-col>
                </v-row>
                <v-row class="body-2 mt-0">
                  <v-col class="py-0 font-weight-medium nearblack--text">
                    {{ isActiveLabel }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-row class="subtitle-2">
                  <v-col class="midgray--text">
                    Internal Use Only
                  </v-col>
                </v-row>
                <v-row class="body-2 mt-0">
                  <v-col class="py-0 font-weight-medium nearblack--text">
                    {{ isInternalUseOnly }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-row class="subtitle-2">
                  <v-col class="midgray--text">
                    Timesheet-Enabled
                  </v-col>
                </v-row>
                <v-row class="body-2 mt-0">
                  <v-col class="py-0 font-weight-medium nearblack--text">
                    {{ hasTimesheetReport }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-row class="subtitle-2">
                  <v-col class="midgray--text">
                    Reports
                  </v-col>
                </v-row>

                <v-row
                  class="pl-3 body-2"
                >
                  <v-col class="py-0 font-weight-medium nearblack--text">
                    <v-icon
                      class="pr-1"
                      :color="hasPacingReport.color"
                      :title="hasPacingReport.value"
                      small
                    >
                      {{ hasPacingReport.icon }}
                    </v-icon>

                    <span class="outline">Pacing</span>
                  </v-col>
                </v-row>

                <v-row
                  class="pl-3 body-2"
                >
                  <v-col class="py-0 font-weight-medium nearblack--text">
                    <v-icon
                      class="pr-1"
                      :color="hasDiscrepancyReport.color"
                      :title="hasDiscrepancyReport.value"
                      small
                    >
                      {{ hasDiscrepancyReport.icon }}
                    </v-icon>

                    <span class="outline">Discrepancy</span>
                  </v-col>
                </v-row>

                <v-row
                  class="pl-3 body-2"
                >
                  <v-col class="py-0 font-weight-medium nearblack--text">
                    <v-icon
                      class="pr-1"
                      :color="hasHealthAdxReport.color"
                      :title="hasHealthAdxReport.value"
                      small
                    >
                      {{ hasHealthAdxReport.icon }}
                    </v-icon>

                    <span class="outline">AdX Health</span>
                  </v-col>
                </v-row>

                <v-row
                  class="pl-3 body-2"
                >
                  <v-col class="py-0 font-weight-medium nearblack--text">
                    <v-icon
                      class="pr-1"
                      :color="hasHealthNetworkReport.color"
                      :title="hasHealthNetworkReport.value"
                      small
                    >
                      {{ hasHealthNetworkReport.icon }}
                    </v-icon>

                    <span class="outline">Network Health</span>
                  </v-col>
                </v-row>

                <v-row
                  class="pl-3 body-2"
                >
                  <v-col class="py-0 font-weight-medium nearblack--text">
                    <v-icon
                      class="pr-1"
                      :color="hasYieldPartnerReport.color"
                      :title="hasYieldPartnerReport.value"
                      small
                    >
                      {{ hasYieldPartnerReport.icon }}
                    </v-icon>

                    <span class="outline">AdX & OB</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="mt-6">
              <v-col>
                <v-row class="subtitle-2">
                  <v-col class="midgray--text">
                    Teams
                  </v-col>
                </v-row>

                <v-row
                  v-if="teams"
                  class="body-2 mt-0"
                >
                  <v-col>
                    <v-data-table
                      :headers="teamColumns"
                      :items="teams"
                      dense
                      disable-pagination
                      hide-default-footer
                    />
                  </v-col>
                </v-row>

                <v-row
                  v-else
                  class="body-2"
                >
                  <v-col class="py-0 font-weight-medium nearblack--text">
                    False
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card
          class="px-3 pt-3 pb-6 elevation-0"
        >
          <v-card-subtitle class="font-weight-bold midgray--text text-uppercase">
            Change Log
          </v-card-subtitle>

          <v-card-text>
            <v-row>
              <v-col>
                <v-row class="body-2">
                  <v-col class="py-0 font-weight-medium">
                    <span class="pr-1 midgray--text">Created on</span>

                    <span class="nearblack--text">
                      {{ createdOn }}
                    </span>

                    <span class="px-1 midgray--text">by</span>

                    <span class="nearblack--text">
                      {{ createdBy }}
                    </span>
                  </v-col>
                </v-row>

                <v-row
                  v-if="updatedBy"
                  class="body-2"
                >
                  <v-col class="py-0 font-weight-medium">
                    <span class="pr-1 midgray--text">Updated on</span>

                    <span class="nearblack--text">
                      {{ updatedOn }}
                    </span>

                    <span class="px-1 midgray--text">by</span>

                    <span class="nearblack--text">
                      {{ updatedBy }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import dates from 'src/utils/dates'
import gam from 'src/utils/gam'

const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')
const { mapState: mapLayoutState } = createNamespacedHelpers('layout')
const { mapGetters: mapToolsGetters, mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'OrganizationProfile',
  data() {
    return {
      teamColumns: [
        {
          sortable: true,
          text: 'Team Name',
          value: 'teamName',
        },
        {
          sortable: true,
          text: 'Team ID',
          value: 'teamId',
        },
        {
          sortable: true,
          text: 'Team Code',
          value: 'teamCode',
        },
      ],
    }
  },
  computed: {
    ...mapAuthGetters(['isInternalAdminUser']),
    ...mapLayoutState({
      icons: (state) => state.icons,
      valueIcons: (state) => state.icons.values,
    }),
    ...mapToolsGetters({
      organization: 'selectedSearch',
    }),
    ...mapToolsState({
      reportId: (state) => state.reportId,
    }),
    isOurCompany() {
      const { reportId } = this
      return ['0', 'oao'].includes(reportId)
    },

    adServerUrl() {
      const { organization = {} } = this
      const { networkCode } = organization

      if (!networkCode) return false

      return gam.network(networkCode)
    },
    isDeleted() {
      const { organization = {} } = this
      const { isDeleted } = organization

      return isDeleted
    },
    isActive() {
      const { organization = {} } = this
      const { isActive } = organization

      return isActive
    },
    isActiveColor() {
      const { isActive, valueIcons } = this
      const status = String(isActive)
      const { color = 'nearblack' } = valueIcons[status] || {}

      return color
    },
    isActiveLabel() {
      if (this.isDeleted) return 'Deleted'
      return this.isActive ? 'Active' : 'Inactive'
    },
    hasPacingReport() {
      const { organization = {}, valueIcons } = this
      const { hasPacing: value, hasPacingReport = false } = organization
      const propertyName = String(hasPacingReport).toLowerCase()

      const styles = valueIcons[propertyName] || {}

      return { ...styles, value }
    },
    hasDiscrepancyReport() {
      const { organization = {}, valueIcons } = this
      const { hasDiscrepancy: booleanValue, hasDiscrepancyReport = false } = organization
      const propertyName = String(hasDiscrepancyReport).toLowerCase()

      const styles = valueIcons[propertyName] || {}

      return { ...styles, value: booleanValue }
    },
    hasHealthAdxReport() {
      const { organization = {}, valueIcons } = this
      const { hasHealthAdx: value, hasHealthAdxReport = false } = organization
      const propertyName = String(hasHealthAdxReport).toLowerCase()

      const styles = valueIcons[propertyName] || {}

      return { ...styles, value }
    },
    hasHealthNetworkReport() {
      const { organization = {}, valueIcons } = this
      const { hasHealthNetwork: value, hasHealthNetworkReport = false } = organization
      const propertyName = String(hasHealthNetworkReport).toLowerCase()

      const styles = valueIcons[propertyName] || {}

      return { ...styles, value }
    },
    hasYieldPartnerReport() {
      const { organization = {}, valueIcons } = this
      const { hasProgrammatic: value, hasYieldPartnerReport = false } = organization
      const propertyName = String(hasYieldPartnerReport).toLowerCase()

      const styles = valueIcons[propertyName] || {}

      return { ...styles, value }
    },
    hasTimesheetReport() {
      const { organization = {} } = this
      const { hasTimesheetReport } = organization

      return hasTimesheetReport
    },
    isInternalUseOnly() {
      const { organization = {} } = this
      const { isInternalUseOnly } = organization

      return isInternalUseOnly
    },
    teams() {
      const { organization = {} } = this
      const { teams = [] } = organization

      return teams
    },
    createdBy() {
      const { organization = {} } = this
      const unknownActor = 'Unknown'
      const { assoc_email = unknownActor, updated_dt } = organization

      return !updated_dt ? assoc_email : unknownActor
    },
    createdOn() {
      const { organization = {} } = this
      const { created_dt } = organization

      return dates.getFormattedLocalDateTime(created_dt)
    },
    updatedBy() {
      const { organization = {} } = this
      const { assoc_email, updated_dt } = organization

      return updated_dt ? assoc_email : null
    },
    updatedOn() {
      const { organization = {} } = this
      const { updated_dt } = organization

      if (!updated_dt) return 'Date Unknown'

      return dates.getFormattedLocalDateTime(updated_dt)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-card__subtitle {
  letter-spacing: 0.025rem;
}
</style>
